<template>
  <div>
    <ul id="element-settings" class="uk-switcher uk-margin">
      <li class="uk-active">
        <div class="uk-form-stacked">
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <label class="uk-form-label">Titel</label>
              <div class="uk-form-controls">
                <input class="uk-input" v-model="form.title">
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label">Inhalt</label>
              <div class="uk-form-controls">
                <editor :init="initEditor" v-model="form.content"></editor>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label">Bild</label>
              <select-image :source="form.image" @image-selected="onImageSelected" @image-removed="onImageRemoved" />
              <input type="text" class="uk-input" v-model="form.image" placeholder="http://">
            </div>
            <div class="uk-margin">
              <div class="uk-grid uk-child-width-1-2@m">
                <div>
                  <div>
                  <label class="uk-form-label">Breite</label>
                  <input type="text" class="uk-input" v-model="form.image_width" placeholder="auto" />
                </div>
                </div>
                <div>
                  <label class="uk-form-label">Höhe</label>
                  <input type="text" class="uk-input" v-model="form.image_height" placeholder="auto" />
                </div>
              </div>
              <p class="uk-text-small uk-text-muted uk-margin-small">Erklärungstext hier rein!</p>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label">Alt Text</label>
              <input type="text" class="uk-input" v-model="form.image_alt" />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-margin">
          <div>
            <panel-settings :form="form" />
            <title-settings :form="form" />
            <image-settings :form="form" />
            <general-settings :form="form" />
          </div>
        </div>
      </li>
      <li>
        <div class="uk-width-1-1 uk-margin">
          <div>
            <panel-settings :form="form" />
            <title-settings :form="form" />
            <image-settings :form="form" />
            <general-settings :form="form" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import GeneralSettings from './../GeneralSettings'
import PanelSettings from './../settings/Panel'
import TitleSettings from './../settings/Title'
import ImageSettings from './../settings/Image'
import SelectImage from '@/components/finder/SelectImage'
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/code'
import Editor from '@tinymce/tinymce-vue'
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {
    Editor,
    SelectImage,
    PanelSettings,
    TitleSettings,
    ImageSettings,
    GeneralSettings
  },
  data () {
    return {
      form: {
        content: ''
      },
      initEditor: {
        menubar: false,
        branding: false,
        relative_urls: false,
        document_base_url: '/',
        min_height: 425,
        max_height: 800,
        toolbar: 'bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link neqstImage | code',
        plugins: 'code link autoresize lists advlist',
        autoresize_bottom_margin: 50,
        language_url: '/tinymce/langs/de.js',
        language: 'de',
        skin_url: '/tinymce/skins/ui/oxide',
        content_css: '/tinymce/skins/content/default/content.min.css',
        external_plugins: {
          neqstImage: '/tinymce/plugins/image-picker/plugin.js'
        },
        valid_elements: '*[*]'
      },
      showSettings: false
    }
  },
  mounted () {
    this.form = this.data
  },
  methods: {
    onImageSelected (image) {
      this.form.image = image
    },
    onImageRemoved () {
      this.form.image = ''
    }
  }
}
</script>
