<template>
  <div class="uk-grid uk-child-width-1-1" uk-grid>
    <div>
      <div class="uk-h5 uk-text-uppercase">Bild</div>
      <div class="uk-margin">
        <label class="uk-form-label">Ausrichtung</label>
        <select class="uk-select" v-model="form.image_align">
          <option value="top">Oben</option>
          <option value="bottom">Unten</option>
          <option value="left">Links</option>
          <option value="right">Rechts</option>
        </select>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">Grid Breite</label>
        <select
          class="uk-select"
          v-model="form.image_grid_width"
          :disabled="form.image_align === 'top' || form.image_align === 'bottom'"
        >
          <option value="auto">Auto</option>
          <option value="1-2">50%</option>
          <option value="1-3">33%</option>
          <option value="1-4">25%</option>
          <option value="1-5">20%</option>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
          <option value="xlarge">X-Large</option>
          <option value="xxlarge">XX-Large</option>
        </select>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">Grid Spalten Abstand</label>
        <select
          class="uk-select"
          v-model="form.image_grid_column_gap"
          :disabled="form.image_align === 'top' || form.image_align === 'bottom'"
        >
          <option value="small">Klein</option>
          <option value="medium">Medium</option>
          <option value>Standard</option>
          <option value="large">Groß</option>
          <option value="collapse">Ohne</option>
        </select>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">Grid Breakpoint</label>
        <select
          class="uk-select"
          v-model="form.image_grid_breakpoint"
          :disabled="form.image_align === 'top' || form.image_align === 'bottom'"
        >
          <option value>Immer</option>
          <option value="s">Small (Phone Landscape)</option>
          <option value="m">Medium (Tablet Landscape)</option>
          <option value="l">Large (Desktop)</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  }
};
</script>