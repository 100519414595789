<template>
  <div class="uk-grid uk-child-width-1-1" uk-grid>
    <div>
      <div class="uk-h5 uk-text-uppercase">Karte</div>
      <div class="uk-margin">
        <label class="uk-form-label">Style</label>
        <select class="uk-select" v-model="form.panel_style">
          <option value>Ohne</option>
          <option value="card-default">Karte Standard</option>
          <option value="card-primary">Karte Primär</option>
          <option value="card-secondary">Karte Sekundär</option>
          <option value="card-hover">Karte Hover</option>
        </select>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">Innenabstand</label>
        <select
          class="uk-select"
          v-model="form.panel_content_padding"
          :disabled="!form.panel_style"
        >
          <option value="small">Klein</option>
          <option value>Standard</option>
          <option value="large">Groß</option>
        </select>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">Höhe</label>
        <select
          class="uk-select"
          v-model="form.panel_height"
        >
          <option value="small">Klein</option>
          <option value>Standard</option>
          <option value="medium">Mittel</option>
          <option value="large">Groß</option>
          <option value="1-1">100%</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>
