<template>
  <div class="uk-grid uk-child-width-1-1" uk-grid>
    <div>
      <div class="uk-h5 uk-text-uppercase">Titel</div>
      <div class="uk-margin">
        <label class="uk-form-label">Style</label>
        <select class="uk-select" v-model="form.title_style">
          <option value>Ohne</option>
          <option value="heading-2xlarge">2Xlarge</option>
          <option value="heading-xlarge">XLarge</option>
          <option value="heading-large">Large</option>
          <option value="heading-medium">Medium</option>
          <option value="heading-small">Small</option>
          <option value="text-bold">Fett</option>
          <option value="h1">H1</option>
          <option value="h2">H2</option>
          <option value="h3">H3</option>
          <option value="h4">H4</option>
          <option value="h5">H5</option>
          <option value="h6">H6</option>
        </select>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">Dekoration</label>
        <select class="uk-select" v-model="form.title_decoration">
          <option value>Ohne</option>
          <option value="divider">Divider</option>
          <option value="bullet">Bullet</option>
          <option value="line">Line</option>
        </select>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">HTML Element</label>
        <select class="uk-select" v-model="form.title_element">
          <option value="h1">H1</option>
          <option value="h2">H2</option>
          <option value="h3">H3</option>
          <option value="h4">H4</option>
          <option value="h5">H5</option>
          <option value="h6">H6</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>
